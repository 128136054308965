<style lang="scss">
@import "../../common/style/common.scss";

#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.browser_notice_message_box {
    z-index: 999999999;
}

</style>
<template>
    <el-container>
        <!--整体框架-->
        <app-container v-if="!outsideFramework"></app-container>
        <!--登录框 注册框-->
        <login-or-register></login-or-register>
        <!--脱离框架本身的路由-->
        <router-view v-if="outsideFramework"></router-view>
        <!--订单定时提醒-->
        <app-order-reminder v-if="showNotice"></app-order-reminder>
    </el-container>
</template>

<script>
import appContainer from '../components/container/index.vue'
import loginOrRegister from '../../components/account/loginOrRegister/index.vue'
import {browserType, isMobile} from '@/common/js/Utils'
import {getApolloConfigOptions} from '@/data/index'
import AppOrderReminder from "@/www/components/app-order-reminder/index.vue";

export default {
    data() {
        return {
            showNotice: false
        }
    },
    components: {
        loginOrRegister,
        appContainer,
        AppOrderReminder
    },
    mounted() {
        //限制google浏览器使用
        setTimeout(() => {
            this.showNotice = true;
            if (!isMobile() && browserType() !== 'Chrome') {
                this.$alert('为了更好的体验，请使用最新版本的Chrome浏览器登录', '提示', {
                    confirmButtonText: '确定',
                    customClass: "browser_notice_message_box",
                    callback: () => {
                        window.location.href = 'https://www.google.cn/chrome/';
                    }
                });
            }
        }, 1000)
        let account = this.localStorage.getObject("account") || {};
        delete account.loginPwd
        this.localStorage.setObject('account', account)
        getApolloConfigOptions();
    },
    computed: {
        outsideFramework() {
            return this.$store.state.outsideFramework
        }
    }
}
</script>


